import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import { useEffect, useState, useContext } from "react";
import { formatPhoneNumber } from "../../libs/helpers";
import Button from "../Button";
import ConfirmModal from "../ConfirmModal";
import { AuthContext } from "../../context/authContext";
import { useNavigate } from "react-router";
import Alert from "../Alert";

export default function SurveyKeysTableWithPagination(props: { surveyKeys: Array<any>; limit: number }) {
  const { surveyKeys, limit } = props;

  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentSurveyKeys, setCurrentSurveyKeys] = useState<Array<any>>([]);
  const [showConfirmExpireModal, setShowConfirmExpireModal] = useState<boolean>(false);
  const [expiringSurveyKeyId, setExpiringSurveyKeyId] = useState<number>();

  const range = (start: number, end: number) => {
    //@ts-ignore
    return [...Array(end).keys()].map((el) => el + start);
  };

  function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(" ");
  }

  const start = (currentPage - 1) * limit + 1;
  const end = currentPage * limit <= surveyKeys.length ? currentPage * limit : surveyKeys.length;
  const pagesCount = Math.ceil(surveyKeys.length / limit);
  const pages = range(1, pagesCount);

  useEffect(() => {
    setCurrentSurveyKeys(surveyKeys.slice(start - 1, end));
  }, [start, end, currentPage]);

  async function expireSurveyKey(surveyKeyId: number): Promise<void> {
    try {
      if (auth.sessionInfo?.idToken) {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/strata/surveys/key/${surveyKeyId}`, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: auth.sessionInfo.idToken,
          },
          body: JSON.stringify({
            expiration_date: new Date(),
          }),
        });
        if (response.status == 200) {
          window.location.reload();
          return;
        }
        alert("An error occurred expiring assessment key");
      } else {
        navigate("/login");
      }
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <div className="overflow-scroll">
      <div className="mt-5">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Assessment Keys</h1>
        </div>
        <div className="flex flex-col">
          <div className="inline-block min-w-full py-2 align-middle">
            <div className="shadow-sm ring-1 ring-black ring-opacity-5">
              <table className="min-w-full border-separate" style={{ borderSpacing: 0 }}>
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                    >
                      Key
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                    >
                      Email
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                    >
                      Phone Number
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                    >
                      Taken?
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                    >
                      Email Send Date
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                    >
                      Key Expiration
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                    ></th>
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {currentSurveyKeys?.map((surveyKey: any, index: number) => (
                    <tr key={index} className={surveyKey.is_taken ? "bg-green-50" : new Date(surveyKey.expiration_date) < new Date() ? "bg-red-50" : ""}>
                      <td
                        className={classNames(
                          index !== surveyKeys.length - 1 ? "border-b border-gray-200" : "",
                          "whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8"
                        )}
                      >
                        {surveyKey.survey_key}
                      </td>
                      <td
                        className={classNames(
                          index !== surveyKeys.length - 1 ? "border-b border-gray-200" : "",
                          "whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell"
                        )}
                      >
                        {surveyKey.email}
                      </td>
                      <td
                        className={classNames(
                          index !== surveyKeys.length - 1 ? "border-b border-gray-200" : "",
                          "whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell"
                        )}
                      >
                        {formatPhoneNumber(surveyKey.phone_number?.slice(-10))}
                      </td>
                      <td
                        className={classNames(
                          index !== surveyKeys.length - 1 ? "border-b border-gray-200" : "",
                          `whitespace-nowrap px-3 py-4 text-sm sm:table-cell ${
                            surveyKey.is_taken ? "text-green-500" : surveyKey.is_started ? "text-yellow-500" : "text-gray-500"
                          }`
                        )}
                      >
                        {surveyKey.is_taken ? "Taken" : surveyKey.is_started ? "Started" : new Date(surveyKey.expiration_date) < new Date() ? "Expired" : "Not Taken"}
                      </td>
                      <td
                        className={classNames(
                          index !== surveyKeys.length - 1 ? "border-b border-gray-200" : "",
                          "whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell"
                        )}
                      >
                        {surveyKey.email_send_date
                          ? new Date(surveyKey.email_send_date).toLocaleDateString() + " " + new Date(surveyKey.email_send_date).toLocaleTimeString()
                          : "Not Sent"}
                      </td>
                      <td
                        className={classNames(
                          index !== surveyKeys.length - 1 ? "border-b border-gray-200" : "",
                          "whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell"
                        )}
                      >
                        {surveyKey.expiration_date
                          ? new Date(surveyKey.expiration_date).toLocaleDateString() + " " + new Date(surveyKey.expiration_date).toLocaleTimeString()
                          : "Not Sent"}
                      </td>
                      <td
                        className={classNames(
                          index !== surveyKeys.length - 1 ? "border-b border-gray-200" : "",
                          "whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell"
                        )}
                      >
                        {new Date(surveyKey.expiration_date) > new Date() && !surveyKey.is_taken && (
                          <Button
                            text="Expire"
                            size="sm"
                            variant="danger"
                            onClick={() => {
                              setShowConfirmExpireModal(true);
                              setExpiringSurveyKeyId(surveyKey.survey_key_id);
                            }}
                          />
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <ConfirmModal
                open={showConfirmExpireModal}
                setOpen={setShowConfirmExpireModal}
                title="Expire Assessment Key"
                text="Are you sure you want to expire this assessment key?"
                onConfirm={() => expiringSurveyKeyId && expireSurveyKey(expiringSurveyKeyId)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-between bg-white py-3">
        <div className="flex flex-1 items-center justify-between">
          <div>
            <p className="text-sm text-gray-700">
              {surveyKeys.length > 0 ? (
                <span>
                  Showing <span className="font-medium">{start}</span> to <span className="font-medium">{end}</span> of{" "}
                  <span className="font-medium">{surveyKeys.length}</span> assessment keys
                </span>
              ) : (
                <span>No assessment keys</span>
              )}
            </p>
          </div>
          <div>
            <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
              <button
                onClick={() => setCurrentPage(currentPage - 1)}
                className={`${
                  currentPage === 1 && "opacity-30"
                } relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20`}
                disabled={currentPage === 1}
              >
                <span className="sr-only">Previous</span>
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
              </button>
              {pages.map((page) => (
                <button
                  key={page}
                  aria-current="page"
                  onClick={() => setCurrentPage(page)}
                  className={
                    page === currentPage
                      ? "relative z-10 inline-flex items-center border border-indigo-500 bg-indigo-50 px-4 py-2 text-sm font-medium text-indigo-600 focus:z-20"
                      : "relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                  }
                  disabled={page === currentPage}
                >
                  {page}
                </button>
              ))}
              <button
                onClick={() => setCurrentPage(currentPage + 1)}
                className={`${
                  currentPage === pages.length && "opacity-30"
                } relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20`}
                disabled={currentPage === pages.length}
              >
                <span className="sr-only">Next</span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}
