import { Menu } from "@headlessui/react";
import { EllipsisVerticalIcon, TrashIcon } from "@heroicons/react/24/solid";
import { SurveyEvent, SurveyEventStatus, SurveyEventType } from "../../types";
import useApi from "../../hooks/useApi";
import Button from "../../components/Button";
import { useState } from "react";
import ConfirmModal from "../../components/ConfirmModal";
import { formatDistance, formatDistanceToNow } from "date-fns";
import { se } from "date-fns/locale";

const eventDescriptions = [
  {
    typeId: SurveyEventType.SendStrataTeamReminder,
    displayName: "Send Strata Team Emails",
    description: "Sends members of the Strata Intel team a reminder that the assessment is starting soon",
    for: "Internal",
  },
  {
    typeId: SurveyEventType.SendPreSurveyAdminReminder,
    displayName: "Send Pre-Assessment Emails",
    description: "Sends team admin members an email reminding them of the upcoming assessment and to ensure participants are updated",
    for: "Admins",
  },
  {
    typeId: SurveyEventType.StartSurvey,
    displayName: "Start Assessment",
    description: "Starts the assessment, sends emails and texts to participants",
    for: "Participants",
  },
  {
    typeId: SurveyEventType.SendParticipantReminder_1,
    displayName: "Send Reminders 1",
    description: "Sends reminder emails and texts to participants",
    for: "Participants",
  },
  {
    typeId: SurveyEventType.SendParticipantReminder_2,
    displayName: "Send Reminders 2",
    description: "Sends reminder emails and texts to participants",
    for: "Participants",
  },
  {
    typeId: SurveyEventType.SendAdminDailySummary_1,
    displayName: "Send Summary Emails 1",
    description: "Sends summary emails to admins outlining assessment progress",
    for: "Admins",
  },
  {
    typeId: SurveyEventType.SendAdminDailySummary_2,
    displayName: "Send Summary Emails 2",
    description: "Sends summary emails to admins outlining assessment progress",
    for: "Admins",
  },
  {
    typeId: SurveyEventType.CloseSurvey,
    displayName: "Close Assessment",
    description: "Closes the assessment, calculates final values, and sends admins an email to schedule a consultation with Dr. Ashton",
    for: "Admins",
  },
];

export default function SurveyEvents(props: { surveyEvents: SurveyEvent[]; surveyId: number }) {
  const { surveyEvents, surveyId } = props;

  const { deleteApiData } = useApi();

  async function deleteEvent(surveyEventId: number) {
    const { response } = await deleteApiData(`/strata/surveys/events/${surveyEventId}`);

    if (response.status === 200) {
      window.location.reload();
    } else {
      console.error(response);
      alert("Error deleting event");
    }
  }

  surveyEvents.sort((a, b) => new Date(a.fire_date).getTime() - new Date(b.fire_date).getTime());

  return (
    <div className="sm:flex-auto">
      <div className="flex justify-between items-center mt-3">
        <h1 className="text-xl font-semibold text-gray-900">Events</h1>
      </div>
      {surveyEvents.length <= 0 ? (
        <div className="text-gray-500 mb-3 text-sm">No scheduled events</div>
      ) : (
        <ul className="divide-y divide-gray-300 mb-3">
          {surveyEvents.map((event, index) => (
            <li
              key={index}
              className={`${event.survey_event_status_id === SurveyEventStatus.Completed ? "opacity-60" : ""} flex items-center justify-between gap-x-6 py-2`}
            >
              <div className="w-1/2">
                <div className="flex items-start gap-x-3">
                  <p className="text-sm font-semibold leading-6 text-gray-900">
                    {eventDescriptions.find((desc) => desc.typeId === event.survey_event_type_id)?.displayName}
                  </p>

                  <p
                    className={`mt-0.5 whitespace-nowrap rounded-md px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset ${
                      event.survey_event_status_id === SurveyEventStatus.Enabled
                        ? "bg-green-100 text-green-600 ring-green-400"
                        : event.survey_event_status_id === SurveyEventStatus.Pending
                        ? "bg-blue-100 text-blue-500 ring-blue-500"
                        : event.survey_event_status_id === SurveyEventStatus.Failed
                        ? "bg-red-100 text-red-600 ring-red-600"
                        : "bg-gray-100 text-gray-400 ring-gray-300"
                    }
                    }`}
                  >
                    {SurveyEventStatus[event.survey_event_status_id]}
                  </p>
                </div>
                <div className="items-center gap-x-2 text-xs leading-5 text-gray-500">
                  <p className="">{eventDescriptions.find((desc) => desc.typeId === event.survey_event_type_id)?.description}</p>
                </div>
              </div>
              <div className="items-center gap-x-2 text-xs leading-5 text-gray-500 ">
                <div className="">
                  {`${new Date() < new Date(event.fire_date) ? "Triggers on" : "Triggered on"}`} on{" "}
                  <span className="text-gray-800 font-bold">{new Date(event.fire_date).toLocaleString()}</span>{" "}
                </div>
                <div className="">
                  <span className="text-xs text-gray-500 ml-1">(UTC: {new Date(event.fire_date).toISOString()})</span>
                </div>
              </div>
              <div className="flex flex-none items-center gap-x-4 text-right w-1/6 justify-end">
                <div>
                  <div className={`${new Date() < new Date(event.fire_date) ? "text-blue-500" : "text-gray-400"}  text-sm font-semibold`}>
                    {" "}
                    {formatDistance(new Date(event.fire_date), new Date(), {
                      addSuffix: true,
                    })}
                  </div>
                </div>
                <Menu as="div" className={`relative flex-none ${event.survey_event_status_id === SurveyEventStatus.Completed && "invisible"}`}>
                  <Menu.Button className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                    <span className="sr-only">Open options</span>
                    <EllipsisVerticalIcon aria-hidden="true" className="h-5 w-5" />
                  </Menu.Button>
                  <Menu.Items className="cursor-pointer hover:bg-gray-100 absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in">
                    <Menu.Item>
                      <div onClick={() => deleteEvent(event.survey_event_id)} className="flex items-center px-2 text-sm leading-6 text-gray-900 data-[focus]:bg-gray-50">
                        <TrashIcon className="h-4 w-4 mr-1 text-red-600" /> Delete
                      </div>
                    </Menu.Item>
                  </Menu.Items>
                </Menu>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
